<template>
  <div>
    <el-empty :image-size="300" description="页面不存在或无访问权限" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>